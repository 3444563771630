<template>
    <div class="page">
        <el-row type="flex" justify="center">
            <el-col :xl="11">
                <div class="left_box">
                    <!-- logo -->
                    <el-image
                        :src="logo"
                        fit="contain"
                        style="width: 430px; height: 34rpx;"
                    ></el-image>
                    <!-- nav -->
                    <div class="login_nav no_select">
                        <div :class="{ active: true }">找回账号密码</div>
                        <!-- <div>微信登录</div> -->
                    </div>
                    <!-- form -->
                    <el-form
                        ref="form"
                        :rules="rules"
                        label-position="top"
                        :model="form"
                        label-width="80px"
                        style="margin-top: 50px;"
                    >
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="form.phone"
                                placeholder="请输入手机号"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="newPassword">
                            <el-input
                                type="password"
                                v-model="form.newPassword"
                                placeholder="请输入密码"
                                show-password
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="验证码" prop="phone_code">
                            <el-input
                                v-model="form.phone_code"
                                placeholder="请输入验证码"
                            ></el-input>
                            <span
                                class="send_code_span"
                                :class="{ couting: sec > 0 }"
                                @click="toSendCode"
                                >{{
                                    sec > 0
                                        ? sec + "秒后可重新获取"
                                        : "获取验证码"
                                }}</span
                            >
                        </el-form-item>
                        
                        <el-form-item label="确认密码" prop="confirmPassword">
                            <el-input
                                type="password"
                                v-model="form.confirmPassword"
                                placeholder="请再次输入密码"
                                show-password
                            ></el-input>
                        </el-form-item>
                    </el-form>

                    <div
                        class="login_btn no_select"
                        :class="{ visible: !isSubmit }"
                        v-loading="isSubmit"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                        @click="onSubmit"
                    >
                        确认修改<el-image
                            :src="require('@/assets/login/enter.png')"
                            fit="none"
                            style="width: 15px; height: 15px;"
                        ></el-image>
                    </div>
                </div>
            </el-col>
            <el-col :xl="13">
                <el-image
                    style="width: 100%; height: 100vh; min-height: 875px;"
                    :src="require('@/assets/login/bg.png')"
                    fit="cover"
                ></el-image>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value !== this.form.newPassword) {
                callback(new Error("两次输入密码不一致"));
            } else {
                callback();
            }
        };

        return {
            isSubmit: false,
            sec: 0,
            form: {
                phone: "",
                phone_code: "",
                newPassword: "",
                confirmPassword: "",
            },
            rules: {
                phone: [
                    {
                        required: true,
                        message: "请填写手机号",
                        trigger: "blur",
                    },
                ],
                phone_code: [
                    {
                        required: true,
                        message: "请填写验证码",
                        trigger: "blur",
                    },
                ],
                newPassword: [
                    {
                        required: true,
                        message: "请填写密码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        message: "请填写至少6位数的密码",
                        trigger: "blur",
                    },
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: "请再次输入密码",
                        trigger: "blur",
                    },
                    { validator: validatePass2, trigger: "blur" },
                ],
            },
        };
    },
    computed: {
        logo() {
            return this.$store.getters["users/owner"].logo;
        },
    },
    methods: {
        ...mapActions("users", ["sendCode", "forgetPwd"]),

        /**
         * 发送验证码
         */
        toSendCode() {
            if (this.sec > 0) return;
            this.$refs.form.validateField("phone", (err) => {
                if (!err) {
                    this.sec = 60;
                    this.couter = setInterval(() => {
                        this.sec--;
                        if (this.sec <= 0) {
                            clearInterval(this.couter);
                        }
                    }, 1000);
                    console.log(this.form.phone);
                    this.sendCode({ mobile: this.form.phone, type: 4 })
                        .then((res) => {
                            console.log(res);
                            this.$alert(
                                "短信已下发，稍后请留意短信。",
                                "提示",
                                {
                                    confirmButtonText: "确定",
                                    callback: () => {},
                                }
                            );
                        })
                        .catch((e) => {
                            console.error(e);
                            this.$message.error(e.msg);
                        });
                }
            });
        },

        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.isSubmit = true;
                    this.forgetPwd({
                        ...this.form,
                    })
                        .then((res) => {
                            this.isSubmit = false;
                            console.log(res);
                            this.$alert(res.data.msg, "修改成功", {
                                confirmButtonText: "确定",
                                callback: () => {
                                    this.$router.push({ name: "Login" });
                                },
                            });
                        })
                        .catch((e) => {
                            this.isSubmit = false;
                            console.error(e);
                            this.$message.error(e.msg);
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    background: linear-gradient(to bottom, #282a2c, #06080a);
    height: 100vh;
    min-width: 680px;
    overflow: hidden;
}

.left_box {
    padding-top: 80px;
    margin: 0 auto;
    width: max-content;

    .login_nav {
        color: #9c9c9c;
        font-size: 22px;
        display: flex;
        align-items: flex-end;
        margin-top: 50px;

        > div {
            margin-right: 40px;
            padding-bottom: 17px;
            cursor: pointer;
        }

        > div.active {
            position: relative;
            font-size: 28px;
            letter-spacing: 2px;
            color: #ffffff;

            &::after {
                content: "";
                width: 30px;
                height: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #e50112;
            }
        }
    }

    .form {
        margin-top: 60px;

        .account,
        .password {
            border: 1px solid #47474d;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding: 25px;
            width: 540px;

            &.focus {
                border: 1px solid #ffffff;
            }

            .account_input,
            .password_input {
                background: none;
                border: 0;
                outline: none;
                background-color: transparent;

                color: #ffffff;
                font-size: 18px;
                caret-color: #4db9d5;

                margin-left: 30px;
                width: 100%;
            }
        }

        .password {
            margin-top: 30px;

            .el-icon-view {
                font-size: 24px;
                color: #393940;
                cursor: pointer;

                &:hover {
                    filter: brightness(2);
                }
            }
        }
    }

    .login_btn {
        width: 100%;
        height: 80px;
        background-color: #4db9d5;
        border-radius: 5px;
        font-size: 20px;
        color: #ffffff;
        line-height: 80px;
        text-align: center;
        margin-top: 60px;

        &.visible:active {
            filter: brightness(1.2);
        }

        .el-image {
            margin-left: 10px;
        }
    }
}

.send_code_span {
    cursor: pointer;
    color: #0080ff;
    position: absolute;
    top: -50px;
    right: 0;

    &.couting {
        cursor: unset;
        color: gray;
    }
}
</style>
